/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable no-inline-styles/no-inline-styles */
import {
  Box,
  Button,
  Collapse,
  Container,
  Divider,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import { makeStyles } from "@material-ui/core/styles";
import { Link, graphql } from "gatsby";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import useWidth from "../hooks/useWidth";
import WhiteButton from "../components/v2/WhightButton";
import whatsappMaxCrmbg from "../images/v2/whatsappcrm/whatsapp-bg-3.png";
import effectiveCommunicationbg from "../images/v2/whatsappcrm/effective-communicationbg.png";
import boostConversationbg from "../images/v2/whatsappcrm/boost-covo-2.png";
import growYourBussinessbg from "../images/v2/whatsappcrm/grow-your-bussinessbg.png";
import generateLeadIcon from "../images/v2/whatsappcrm/generate-lead-icon.svg";
import boostSaleIcon from "../images/v2/whatsappcrm/boost-sale-icon.svg";
import bostSaleImg from "../images/v2/whatsappcrm/bost-sale.png";
import footerTick from "../images/v2/whatsappcrm/footer-tick.png";
import clickImage1 from "../images/v2/whatsappcrm/auto-captureleads-1.png";
import groupChat from "../images/v2/whatsappcrm/group-chat.png";
import BroadcastMessage from "../images/v2/whatsappcrm/broadcast-msg-1.png";
import clickImageAi from "../images/v2/whatsappcrm/click-image-ai-3.png";
import clickImageTemplate from "../images/v2/whatsappcrm/click-image-template.png";
import clickImageFlow from "../images/v2/whatsappcrm/click-image-flow.png";
import clickImageCatalog from "../images/v2/whatsappcrm/click-image-catlog-3.png";
import socialMediaBg from "../images/v2/whatsappcrm/socialmedia-bg.png";
import whatsappVideoBg from "../images/v2/whatsappcrm/video-bg.svg";
import socialMediaImage from "../images/v2/whatsappcrm/social-media-icons-banner.png";
import sendTemplate from "../images/v2/whatsappcrm/send-template.png";
import selectedPoints from "../images/v2/whatsappcrm/select-point.svg";
import shareProduct from "../images/v2/whatsappcrm/share-catlog.png";
import Lottie from "lottie-react";
import LazyLoad from "react-lazyload";
import whatsAppCrmConversation from "../animations/chat-animation.json";
import SEO from "../components/SEO";
import whatsappImage from "../images/v2/whatsappcrm/build.png";
import testimonialImage from "../images/v2/whatsappcrm/testimonial.png";
import chatInWhatsapp from "../images/v2/whatsappcrm/chat-in-whatsapp.png";
import autoReply from "../images/v2/whatsappcrm/auto-reply.png";
import broadCastDeal from "../images/v2/whatsappcrm/broadcast-img.png";
import automateChatFlow from "../images/v2/whatsappcrm/automate-chat-flow.png";
import IMDA_SME_Large from "../images/v2/psg/IMDA_SME_Large.webp";

const useStyles = makeStyles((theme) => ({
  SmeStyling: {
    width: "74%",
    margin: "20px auto 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10px auto 0",
    },
  },
  smeFooterText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "center",
    },
  },
  accordionTitle: {
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      textAlign: "center",
      marginBottom: "12px",
      //  marginTop : "-50px",
    },
  },
  accordionTitleConvert: {
    color: "#000",
    marginTop: "-150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      textAlign: "center",
      marginBottom: "12px",
      marginTop: "50px",
    },
    "@media(max-width:1920px)": {
      marginTop: "-75px",
    },
    "@media(min-width:1921px)": {
      marginTop: "-30px",
    },
  },
  accordionTitleWhatsapp: {
    color: "#000",
    marginTop: "-50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      textAlign: "center",
      marginBottom: "12px",
      marginTop: "0px",
    },
    "@media(min-width:1920px)": {
      marginTop: "-50px",
    },
    "@media(min-width:1921px)": {
      marginTop: "50px",
    },
  },
  mobilePad: {
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
  },
  whatsappHome: {
    position: "reletive",
    background: `url(${whatsappMaxCrmbg})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    minHeight: "800px",
    "@media(max-width:1920px)": {
      minHeight: "950px",
    },
    "@media(max-width:1920px)": {
      backgroundSize: "inherit",
    },
  },
  whatsappSliderText: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  generatLeader: {},
  whatsappEffectivecomm: {
    position: "reletive",
    background: `url(${effectiveCommunicationbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  boostConversation: {
    position: "reletive",
    background: `url(${boostConversationbg})`,
    backgroundSize: "inherit",
    backgroundPosition: "center",
    marginBottom: "20px",
    backgroundRepeat: "no-repeat",
    "@media (min-width: 2040px)": {
      backgroundSize: "cover", // Adjust for smaller screens
    },
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  expendButtonInBluebg: {
    backgroundColor: "#65bbd2",
    color: "#fff",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  getStartedBtn: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  buildExperiencesImg: {
    maxWidth: "100%",
    height: "auto",
    position: "relative",
    top: "-75px",
    "@media(max-width:960px)": {
      top: "0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
    [theme.breakpoints.up("xl")]: {
      textAlign: "left!important",
    },
  },
  img: {
    maxWidth: "100%",
    height: "auto",
    willChange: "auto !important",
  },
  experienceImg: {
    maxWidth: "75%",
    height: "auto",
    willChange: "auto !important",
  },
  crmUserimg: {
    maxWidth: "auto",
    height: "auto",
  },
  customButton: {
    fontSize: "22px",
    marginTop: "35px",
  },
  crmheadimg: {
    width: "100%",
    height: "auto",
    willChange: "auto !important",
  },
  smallImg: {
    width: "90%",
    height: "auto",
    "& .gatsby-image-wrapper": {
      willChange: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "24px",
    },
  },
  buildImg: {
    width: "auto",
    height: "80%",
    "& .gatsby-image-wrapper": {
      willChange: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },

  buildExperiences: {
    paddingLeft: "64px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
    },
  },
  list: {
    paddingLeft: "8px",
    alignItems: "flex-start",
    "&.selected ": {
      backgroundColor: "#e3f7f8",
      borderRadius: "4px",
      "@media(max-width:960px)": {
        backgroundColor: "transparent",
      },
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 24,
    backgroundColor: "#08a742",
    borderRadius: "50%",
    padding: "5px",
    marginTop: "10px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  listItemText: {
    cursor: "pointer",
    "& *": {
      fontSize: 20,
      lineHeight: "30px",
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  growYourBussiness: {
    position: "reletive",
    background: `url(${growYourBussinessbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  lineLength: {
    maxWidth: "435px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      textAlign: "center",
    },
  },
  homeSliderTextHead: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      lineHeight: "32px",
    },
  },
  imageBigscreen: {
    [theme.breakpoints.up("xl")]: {
      textAlign: "left",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  whatsAppResponssive: {
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  BannerGrid: {
    backgroundColor: "#fff",
    padding: "16px",
    marginTop: "130px",
    marginLeft: "5px",
    borderRadius: "14px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    "@media(min-width:1920px)": {
      // marginTop: "280px",
    },
    "@media(max-width:1900px)": {
      // marginTop: "200px",
    },
    "@media(max-width:960px)": {
      marginTop: "60px",
    },
  },
  powerUpCardInner1: {
    backgroundColor: "#dbf1e5",
    padding: "14px 20px",
    minHeight: "150px",
    borderRadius: "8px",
  },
  powerUpCardInner2: {
    backgroundColor: "#fff6e3",
    padding: "14px 20px",
    minHeight: "150px",
    borderRadius: "8px",
  },
  powerUpCardInner3: {
    backgroundColor: "#eaf6ff",
    padding: "14px 20px",
    minHeight: "150px",
    borderRadius: "8px",
  },
  socialGrid: {
    display: "flex",
    alignItems: "center",
  },
  BannerGridInner: {
    borderRight: "2px dashed #dde0e2",
    "&:nth-child(6)": {
      borderRight: "0px dashed #fff",
    },
    "@media(max-width:768px)": {
      width: "50%",
      borderRight: "2px dashed #dde0e2",
      borderBottom: "2px dashed #dde0e2",
      "&:nth-child(6)": {
        borderRight: "0px dashed #fff",
        borderBottom: "0px dashed #fff",
      },
      "&:nth-child(5)": {
        borderBottom: "0px dashed #fff",
      },
      "&:nth-child(4)": {
        borderRight: "0px dashed #fff",
      },
      "&:nth-child(2)": {
        borderRight: "0px dashed #fff",
      },
    },
    "@media(max-width:500px)": {
      minHeight: "180px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  whatappCrmPoints: {
    "@media(max-width:768px)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingLeft: "7%",
    },
  },
  whatappCrmPoint: {
    "@media (max-width:768px)": {
      marginBottom: "24px",
      marginRight: "0px!important",
    },
  },
  multipleAgentImage: {},
  connectcustomBlock: {
    background: `url(${socialMediaBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
  },
  faqSection: {
    borderTop: "1px solid #70707038",
    borderBottom: "1px solid #70707038",
  },
  faqTitle: {
    color: "#000",
    display: "flex",
    fontSize: 20,
    marginTop: 2,
    fontWeight: 600,
    marginBottom: 2,
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:768px)": {
      fontSize: "18px",
    },
  },
  faqBox: {
    paddingBottom: "45px",
  },
  faqAnswer: {
    color: "#000",
    fontSize: 18,
    marginTop: 8,
    maxWidth: 920,
    width: "calc( 100% - 50px )",
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  expendButtonFaq: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  selectedDescription: {
    backgroundColor: "#e3f7f8",
    padding: "8px ",
    marginBottom: "24px",
    display: "flex",
    alignItems: "flex-start",
  },
  testimonialLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    borderRight: "1px solid #707070",
    "@media(max-width:768px)": {
      marginBottom: "32px",
      borderRight: "1px solid transparent",
    },
  },
  testImg: {
    width: "300px",
    "@media(max-width:768px)": {
      width: "150px",
    },
  },
  testimonialTextContainer: {
    "@media(max-width:768px)": {
      paddingLeft: "0!important",
    },
  },
  testimonialText: {
    "@media(max-width:768px)": {
      textAlign: "center",
    },
  },
  videoSection: {
    background: `url(${whatsappVideoBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  smr: {
    marginBottom: "8px",
  },
  selectRight: {
    "@media (max-width:960px)": {
      display: "none",
    },
  },
  powerUpCardHead: {
    "@media(max-width:768px)": {
      fontSize: "17px",
    },
  },
  customButtonResponssive: {
    "@media(max-width:960px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  boostSale: {
    maxWidth: "750px",
    width: "100%",
    marginTop: "-20px",
  },
  selectedListContainer: {
    minWidth: "450px",
    "@media(max-width:600px)": {
      minWidth: "auto",
      // marginTop : "300px"
    },
  },
  BannerGridTitle: {
    margin: "0",
  },
  accordSection: {
    "@media(max-width:960px)": {
      paddingTop: "0",
    },
  },
}));

const WhatsAppCrm = (props) => {
  const width = useWidth();
  const classes = useStyles();
  const [activeConversation, setActiveConversation] = React.useState([
    true,
    true,
    true,
  ]);
  const [open, setOpen] = React.useState(0);
  const [selectedOption, setSelectedOption] = React.useState(0);
  const [activeGenerateLead, setActiveGenerateLead] = React.useState(0);
  const [activeEffectivecomm, setActiveEffectivecomm] = React.useState(0);

  const generateLead = React.useMemo(() => {
    const {
      data: { autoLeadCapture, mergeContact, bordcastMessage },
    } = props;
    const generateLead = [
      {
        title: "Auto lead capture",
        description:
          "Save leads in your CRM from incoming chats and work towards converting the conversation into a sales win. Save your time and effort, and never let leads slip through the cracks.",
        img: getImage(autoLeadCapture),
        alt: "Convert conversation into sales with WhatsApp CRM",
      },
      {
        title: "Merge contacts",
        description:
          "Easily find the customer’s chats from multiple channels and merge them into one unified conversation. Associate it with the customer profile to get a holistic view and the context of the conversation.",
        img: getImage(mergeContact),
        alt: "Import contacts from multiple channels - WhatsApp, Facebook and Instagram",
      },
      {
        title: "Broadcast messages",
        description:
          "Keep your prospects informed about your product updates and promotions. Schedule and send messages to your potential clients with the broadcast feature.",
        img: getImage(bordcastMessage),
        alt: "Broadcast messages with CRM with WhatsApp Integration",
      },
    ];

    return generateLead;
  }, []);
  const effectivecomm = React.useMemo(() => {
    const {
      data: {
        oneAccountMultipleAgents,
        AutoReplyWithMessageTemplates,
        addCustomNotesToConversations,
      },
    } = props;
    const effectivecomm = [
      {
        title: "One account, multiple agents",
        description:
          "Ensure no messages go unheard. Have multiple agents available to attend to your customer queries. Categorise incoming chats and assign them to the right person to take over the sales conversation.",
        img: getImage(oneAccountMultipleAgents),
        alt: "One account, multiple agents",
      },
      {
        title: "Auto-reply with message templates",
        description:
          "Reply to the most frequently asked questions instantly with pre-approved message templates. Customise these message templates to personalise your responses.",
        img: getImage(AutoReplyWithMessageTemplates),
        alt: "Convert conversation into sales with WhatsApp CRM",
      },
      {
        title: "Add custom notes to conversations",
        description:
          "Keep your team informed about the customer by signing off with a note. Mention special considerations, customer requirements, expectations, and more.",
        img: getImage(addCustomNotesToConversations),
        alt: "Add custom notes to conversations",
      },
    ];

    return effectivecomm;
  }, []);
  const conversation = [
    {
      title: "Send auto-away messages",
      description:
        "Notify message senders when you are not available to respond to their message. Send an auto-away message when you are away and during your off-business hours.",
    },
    {
      title: "Tag your conversations",
      description:
        "Categorise your leads and contacts as hot, warm, or cold; or based on their demographics, purchase behaviour, etc., with the help of colourful tags, and send non-transactional messages to the segmented audience.",
    },

    {
      title: "Media and interactive messages",
      description:
        "Build engaging conversations by sending a voice message, product video, quotation, document, or other media files without any hassle. Provide instant response to the received query with interactive auto-replies.",
    },
  ];

  const CustomExperience =
    `Centralise conversations by integrating your sales, marketing, and support channels with WhatsApp CRM integration.
	 Promote your products and services with the best WhatsApp business marketing tool.
	 Accelerate your sales cycle through various powerful features of CRM with WhatsApp integration.
	 Win clients collaboratively by empowering your sales representatives, sales managers and the entire team with WhatsApp CRM.
	 Provide quick and all-round support to your customers through personalised and engaging conversations with WhatsApp CRM`.split(
      `.`
    );

  const clickableOptions = [
    {
      name: "Broadcast messages",
      description:
        "Categorise leads with tags and run targeted WhatsApp marketing campaigns. Broadcast discounts, promotions, and more.",
      imageUrl: BroadcastMessage,
    },
    {
      name: "Auto capture leads",
      description:
        "Capture high-intent leads from incoming messages and click-to-WhatsApp ads and enable them to make instant purchases.",
      imageUrl: clickImage1,
    },
    {
      name: "Respond instantly with message templates",
      description:
        "Reply quickly to queries with message templates. Enhance engagement with media messages and interactive CTAs.",
      imageUrl: clickImageTemplate,
    },
    {
      name: "Sell directly with product catalogs",
      description:
        "Showcase your products and services through WhatsApp catalogs and enable leads to make purchases with just a click.",
      imageUrl: clickImageCatalog,
    },
    {
      name: "Communicate through group chats",
      description:
        "Provide exclusive and quick client support, collaboratively resolving issues in WhatsApp Groups.",
      imageUrl: groupChat,
    },
    {
      name: "Respond with Assist AI",
      description:
        "Empower your team to engage with multiple customers simultaneously while AssistAI, your AI chatbot, handles FAQs.",
      imageUrl: clickImageAi,
    },
    {
      name: "Build chat flows",
      description:
        "Predefine your own WhatsApp chat flows and offer end-to-end experience through WhatsApp CRM.",
      imageUrl: clickImageFlow,
    },
    // {
    // 	name: "Automate Whatsapp Workflows",
    // 	description: "description",
    // 	imageUrl: whatsappImage,
    // },
  ];

  const frequentlyAskedQuestions = [
    {
      title: "What is WhatsApp CRM?",
      ans: (
        <React.Fragment>
          WhatsApp CRM is a powerful tool that combines the capabilities of
          WhatsApp with a CRM system.
          <p marginTop={"8px"}>
            With Pepper Cloud, a WhatsApp CRM tool, businesses can efficiently
            manage leads, send instant responses using message templates,
            schedule broadcasts, deliver interactive messages, broadcast
            marketing campaigns and do much more.
          </p>
        </React.Fragment>
      ),
    },
    {
      title: "How can WhatsApp CRM benefit SMEs?",
      ans: "With a CRM system integrated with WhatsApp, businesses can provide personalised customer service, streamline the sales process, and track all customer interactions in one place. With features such as message templates, automated replies, chatbots, tags, etc. businesses can effectively boost their sales and marketing efforts.",
    },
    {
      title: "How long does it take to set up WhatsApp CRM for my business?",
      ans: "If you have all the prerequisites ready, you can start using WhatsApp through the CRM system within a few hours.",
    },
    {
      title: "Can I use an existing number for WhatsApp CRM?",
      ans: "Yes, the existing number can be used for WhatsApp in Pepper Cloud CRM. Additionally, you can use your landline number to set up the WhatsApp account and integrate it with CRM.  ",
    },
    {
      title:
        "Does Pepper Cloud charge for WhatsApp messages sent through the CRM?",
      ans: (
        <React.Fragment>
          No, Pepper Cloud doesn’t charge for the messages. However, you may
          incur charges from WhatsApp. For more details, check out{" "}
          <a href="https://blog.peppercloud.com/whatsapp-api-pricing-everything-you-need-to-know/?utm_source=Website&utm_medium=WhatsAppCRM">
            WhatsApp pricing.
          </a>
        </React.Fragment>
      ),
    },
    {
      title: "Can businesses utilise the PSG grant to avail WhatsApp CRM?",
      ans: (
        <React.Fragment>
          Pepper Cloud, a WhatsApp CRM, is an IMDA-approved digital solution and
          you can use your PSG grant to avail it. Explore an in-depth{" "}
          <a href="https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/">
            guide on the Productivity Solutions Grant (PSG)
          </a>
          &nbsp;application process to gain comprehensive insights into the
          details.
        </React.Fragment>
      ),
    },
    {
      title:
        "How many WhatsApp accounts can be integrated with Pepper Cloud CRM?",
      ans: "You can integrate an unlimited number of WhatsApp accounts with Pepper Cloud CRM.",
    },
    {
      title: "Why should businesses use WhatsApp for sales?",
      ans: "WhatsApp, with its 2 billion active users, is not merely a personal chat platform but is increasingly becoming a pivotal touchpoint for businesses to engage with their customers.",
    },
  ];

  const handleClickCollapse = (index) => () => {
    setOpen((res) => (res === index ? -1 : index));
  };

  console.log(
    { selectedOption },
    `${clickableOptions[selectedOption].imageUrl}`
  );

  return (
    <React.Fragment>
      <SEO
        canonical="/whatsapp-crm"
        description="WhatsApp CRM to broadcast WhatsApp sales and marketing campaigns, swiftly respond to customers with AI chatbots, automation and pre-build templates, share catalogs and more."
        keywords="whatsapp crm,crm with whatsapp integration,crm whatsapp integration,whatsapp integration,WhatsApp-enabled CRM "
        pathname="/whatsapp-crm"
        title="WhatsApp CRM | Best CRM with Whatsapp Integration | Pepper Cloud"
      />
      <Box className={classes.whatsappHome} py={8}>
        <Container>
          <Grid
            alignItems="center"
            className={classes.whatsapphomeGrid}
            container
            justify="center"
            spacing={7}
          >
            {/*<Box alignItems="center" component={Grid} display="flex" flexDirection="column" item md={6} sm={12}>*/}
            <Grid item lg={6} md={6} sm={12}>
              <Box className={classes.homeSliderText}>
                <HeaderTypography
                  className={classes.homeSliderTextHead}
                  color="#000"
                  component="h1"
                  fontSize={40}
                  fontWeight={600}
                  lineHeight="60px"
                  mb={2}
                  mt={4}
                  textAlign="left"
                >
                  Maximise sales wins with WhatsApp CRM
                </HeaderTypography>

                <ParagraphTypography
                  className={classes.lineLength}
                  color="#000"
                  fontSize={18}
                  lineHeight="25px"
                  mb={5}
                  mt={0}
                  textAlign="left"
                  marginTop="30px"
                  marginBottom="20px"
                >
                  Run WhatsApp marketing campaigns, engage in instant and
                  personalised conversations, and boost your sales with the
                  power of WhatsApp CRM.
                </ParagraphTypography>
                <Box className={classes.getStartedBtn}>
                  <Button
                    className={classes.customButton}
                    color="secondary"
                    component={Link}
                    size="large"
                    to="/contact"
                    variant="contained"
                  >
                    Get Started
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              component={Box}
              item
              lg={6}
              maxWidth="100%"
              md={6}
              paddingLeft="20px"
              sm={12}
            >
              <Box alignItems="center" display="flex" justifyContent="flex-end">
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <StaticImage
                    alt="Best WhatsApp CRM"
                    className={classes.smallImg}
                    placeholder="blurred"
                    src="../images/v2/whatsappcrm/whatsapp-user-crmImg.png"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Grid
              alignItems="center"
              className={classes.BannerGrid}
              container
              justify="center"
              spacing={2}
            >
              <Grid
                className={classes.BannerGridInner}
                item
                lg={2}
                md={4}
                sm={6}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box>
                    <Box
                      alt="Message templates"
                      className={classes.smr}
                      component="img"
                      placeholder="blurred"
                      src={sendTemplate}
                      width={52}
                    />
                  </Box>
                  <ParagraphTypography
                    className={classes.BannerGridTitle}
                    color="#000"
                    fontSize={22}
                    fontWeight={600}
                    lineHeight="30px"
                    mt={2}
                    textAlign={"center"}
                  >
                    Send message templates
                  </ParagraphTypography>
                </Box>
              </Grid>
              <Grid
                className={classes.BannerGridInner}
                item
                lg={2}
                md={4}
                sm={6}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box>
                    <Box
                      alt="Product catalogs"
                      className={classes.smr}
                      component="img"
                      placeholder="blurred"
                      src={shareProduct}
                      width={52}
                    />
                  </Box>
                  <ParagraphTypography
                    className={classes.BannerGridTitle}
                    color="#000"
                    fontSize={22}
                    fontWeight={600}
                    lineHeight="30px"
                    mt={2}
                    textAlign={"center"}
                  >
                    Share product catalogs
                  </ParagraphTypography>
                </Box>
              </Grid>
              <Grid
                className={classes.BannerGridInner}
                item
                lg={2}
                md={4}
                sm={6}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box>
                    <Box
                      alt="WhatsApp groups"
                      className={classes.smr}
                      component="img"
                      placeholder="blurred"
                      src={chatInWhatsapp}
                      width={52}
                    />
                  </Box>
                  <ParagraphTypography
                    className={classes.BannerGridTitle}
                    color="#000"
                    fontSize={22}
                    fontWeight={600}
                    lineHeight="30px"
                    mt={2}
                    textAlign={"center"}
                  >
                    Chat in <br /> WhatsApp groups
                  </ParagraphTypography>
                </Box>
              </Grid>
              <Grid
                className={classes.BannerGridInner}
                item
                lg={2}
                md={4}
                sm={6}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box>
                    <Box
                      alt="Auto reply with chatbots"
                      className={classes.smr}
                      component="img"
                      placeholder="blurred"
                      src={autoReply}
                      width={52}
                    />
                  </Box>
                  <ParagraphTypography
                    className={classes.BannerGridTitle}
                    color="#000"
                    fontSize={22}
                    fontWeight={600}
                    lineHeight="30px"
                    mt={2}
                    textAlign={"center"}
                  >
                    Auto-reply <br /> with chatbot
                  </ParagraphTypography>
                </Box>
              </Grid>
              <Grid
                className={classes.BannerGridInner}
                item
                lg={2}
                md={4}
                sm={6}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box>
                    <Box
                      alt="Message broadcast"
                      className={classes.smr}
                      component="img"
                      placeholder="blurred"
                      src={broadCastDeal}
                      width={52}
                    />
                  </Box>
                  <ParagraphTypography
                    className={classes.BannerGridTitle}
                    color="#000"
                    fontSize={22}
                    fontWeight={600}
                    lineHeight="30px"
                    mt={2}
                    textAlign={"center"}
                  >
                    Broadcast deals <br /> and promotions
                  </ParagraphTypography>
                </Box>
              </Grid>
              <Grid
                className={classes.BannerGridInner}
                item
                lg={2}
                md={4}
                sm={6}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box>
                    <Box
                      alt="WhatsApp chat flow"
                      className={classes.smr}
                      component="img"
                      placeholder="blurred"
                      src={automateChatFlow}
                      width={52}
                    />
                  </Box>
                  <ParagraphTypography
                    className={classes.BannerGridTitle}
                    color="#000"
                    fontSize={22}
                    fontWeight={600}
                    lineHeight="30px"
                    mt={2}
                    textAlign={"center"}
                  >
                    Automate <br /> chat flows
                  </ParagraphTypography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box className={classes.videoSection}>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          my={{ md: 4 }}
          textAlign="center"
        >
          <HeaderTypography
            className={classes.accordionTitleWhatsapp}
            component="h2"
            fontWeight={600}
            mb={6}
            mt={0}
          >
            WhatsApp CRM for instant <br /> and intuitive sales conversations
          </HeaderTypography>
          <Grid
            alignItems="center"
            container
            display="flex"
            justifyContent="center"
            spacing={6}
          >
            <Grid item md={10} my={6} xs={12}>
              <div className={classes.svg}>
                <LazyLoad offset={100} once>
                  <Lottie
                    animationData={whatsAppCrmConversation}
                    autoplay={true}
                    loop={true}
                    style={{
                      height: "auto",
                      maxWidth: "1024px",
                      background: "transparent",
                      fill: "none",
                    }}
                  />
                </LazyLoad>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mb={0} mt={0}></Box>
      <Box>
        <Container>
          <Box
            alignItems={"center"}
            display={"flex"}
            justifyContent={"center"}
            width={"100%"}
          >
            <Box maxWidth={"850px"}>
              <HeaderTypography
                alignItems={{ sm: "left" }}
                className={classes.accordionTitle}
                component="h3"
                display="flex"
                fontSize={40}
                fontWeight={700}
                justifyContent="flex-start"
                lineHeight={1.4}
                mb={2}
              >
                <Box
                  alt="Sales CRM feature"
                  component="img"
                  height={{ sm: 40, xs: 40 }[width] || 89}
                  mr={{ sm: 2, xs: 2 }[width] || 3}
                  src={generateLeadIcon}
                />
                Communicate and sell effectively across the customer lifecycle
              </HeaderTypography>
              <ParagraphTypography
                color="#000"
                fontSize="18px"
                lineHeight="24px"
                marginTop="16px"
                textAlign="center"
                marginBottom="50px"
              >
                Maximise growth potential with best WhatsApp CRM solution.
                Create end-to-end shopping experience on WhatsApp and convert
                leads into customers effortlessly.
              </ParagraphTypography>
            </Box>
          </Box>
          <Box>
            <Grid alignItems="flex-start" container justify="center">
              <Grid item md={6} sm={12}>
                <Box
                  display="flex"
                  justifyContent={{
                    xs: "flex-start",
                    sm: "flex-start",
                    md: "center",
                  }}
                >
                  <Box>
                    <List className={classes.selectedListContainer}>
                      {clickableOptions.map((each, index) => (
                        <ListItem
                          alignItems="center"
                          className={
                            selectedOption === index
                              ? `${classes.list} selected list`
                              : `${classes.list} list`
                          }
                          cursor="pointer"
                          key={each.name}
                          onClick={() => setSelectedOption(index)}
                        >
                          <ListItemText className="listItemText">
                            <Box className="accordionimgitem">
                              <Box className="accordionimg">
                                <Box
                                  alt="Sales CRM feature"
                                  component="img"
                                  height={"18px"}
                                  marginLeft={"8px"}
                                  marginTop={"4px"}
                                  mr={"12px"}
                                  src={selectedPoints}
                                />
                              </Box>
                              <Box className="accordionName">{each.name}</Box>
                            </Box>
                            <Box className="responssive-listitem">
                              <Box>
                                <ParagraphTypography
                                  className="desp"
                                  fontSize={"18px"}
                                  m={0}
                                >
                                  {each.description}
                                </ParagraphTypography>
                              </Box>
                              <Box
                                alt={each.name}
                                component={"img"}
                                height={"auto"}
                                maxWidth={"100%"}
                                src={each.imageUrl}
                                title={each.name}
                                width={"100%"}
                              />
                            </Box>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={1} sm={12} />
              <Grid className={classes.selectRight} item lg={5} md={5} sm={12}>
                <Box className={classes.selectedDescription}>
                  <Box
                    alt="Sales CRM feature"
                    component="img"
                    height={"18px"}
                    marginLeft={"8px"}
                    marginTop={"4px"}
                    mr={"12px"}
                    src={selectedPoints}
                  />
                  <ParagraphTypography fontSize={"18px"} m={0}>
                    {clickableOptions[selectedOption].description}
                  </ParagraphTypography>
                </Box>
                <Box textAlign="right">
                  <Box
                    alt={`${clickableOptions[selectedOption].name}`}
                    component={"img"}
                    height={"auto"}
                    maxWidth={"100%"}
                    src={`${clickableOptions[selectedOption].imageUrl}`}
                    title={`${clickableOptions[selectedOption].name}`}
                    width={"100%"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Grid
            alignItems="center"
            className={classes.generatLeader}
            container
            justify="center"
            pb={8}
          >
            <Grid item md={6} sm={12}></Grid>
            <Grid item md={6} sm={12}></Grid>
          </Grid>
        </Container>
      </Box>

      <Box className={classes.boostConversation} pb={3} pt={3}>
        <Box component={Container} textAlign="center">
          <Box>
            <HeaderTypography
              alignItems="center"
              className={classes.accordionTitle}
              component="h3"
              display="flex"
              fontSize="40px"
              fontWeight={700}
              justifyContent="center"
              lineHeight={1.4}
              mb={0}
            >
              <Box
                alt="Sales CRM feature"
                component="img"
                height={{ sm: 40, xs: 40 }[width] || 89}
                mr={{ sm: 2, xs: 2 }[width] || 3}
                src={boostSaleIcon}
              />
              Boost sales conversions with effective conversations
            </HeaderTypography>
          </Box>
        </Box>
        <br />
        <br />
        <Box component={Container} textAlign="center">
          <Box minHeight="260px">
            <Grid container spacing={4}>
              <Grid className={classes.powerUpCard} item md={4} sm={6}>
                <Box className={classes.powerUpCardInner1}>
                  <HeaderTypography
                    className={classes.powerUpCardHead}
                    fontFamily="'SourceSansPro', sans-serif"
                    color="#000"
                    component="h3"
                    fontSize="22px"
                    fontWeight={600}
                    mb={1}
                    mt={0}
                    textAlign={"left"}
                  >
                    One account, multiple agents
                  </HeaderTypography>
                  <ParagraphTypography
                    color="#000"
                    fontSize="16px"
                    m={0}
                    textAlign="left"
                  >
                    Empower your agents to handle customer inquiries seamlessly
                    from any device using a single WhatsApp account.
                  </ParagraphTypography>
                </Box>
              </Grid>
              <Grid className={classes.powerUpCard} item md={4} sm={6}>
                <Box className={classes.powerUpCardInner2}>
                  <HeaderTypography
                    className={classes.powerUpCardHead}
                    fontFamily="'SourceSansPro', sans-serif"
                    color="#000"
                    component="h3"
                    fontSize="22px"
                    fontWeight={600}
                    mb={1}
                    mt={0}
                    textAlign={"left"}
                  >
                    WhatsApp on landline number
                  </HeaderTypography>
                  <ParagraphTypography
                    color="#000"
                    fontSize="16px"
                    m={0}
                    textAlign="left"
                  >
                    Enable customers to message you on your landline number
                    through WhatsApp by configuring your WhatsApp on the
                    landline.
                  </ParagraphTypography>
                </Box>
              </Grid>
              <Grid className={classes.powerUpCard} item md={4} sm={6}>
                <Box className={classes.powerUpCardInner3}>
                  <HeaderTypography
                    className={classes.powerUpCardHead}
                    fontFamily="'SourceSansPro', sans-serif"
                    color="#000"
                    component="h3"
                    fontSize="22px"
                    fontWeight={600}
                    mb={1}
                    mt={0}
                    textAlign={"left"}
                  >
                    WhatsApp CRM for SMEs
                  </HeaderTypography>
                  <ParagraphTypography
                    color="#000"
                    fontSize="16px"
                    m={0}
                    textAlign="left"
                  >
                    Capture and convert leads, maintain client profiles, and win
                    sales conversations with best CRM for WhatsApp.
                  </ParagraphTypography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.multipleAgentImage}>
            <img
              alt="WhatsApp templates and message tags"
              className={classes.boostSale}
              src={bostSaleImg}
            />
          </Box>
          <br />
        </Box>
        <br />
      </Box>
      {/* --------------------------------------- */}
      <Box className={classes.accordSection} py={6}>
        <Container>
          {" "}
          <Box maxWidth={820}>
            <HeaderTypography
              className={classes.accordionTitleConvert}
              component="h3"
              display="flex"
              fontSize={40}
              fontWeight={600}
              justifyContent="center"
              lineHeight={1.5}
              mb={0}
              mt={-6}
              pl={1}
              textAlign="left"
            >
              Convert conversations into customers with best CRM for WhatsApp
            </HeaderTypography>
          </Box>
          <Grid alignItems="center" container justify="center">
            <Grid item md={6} sm={12}>
              <Box
                display="flex"
                justifyContent={{ xs: "center", sm: "center", md: "flex-end" }}
              >
                <Box className={classes.mobilePad} maxWidth={620}>
                  <List>
                    {CustomExperience.map((each) => (
                      <ListItem
                        alignItems="center"
                        className={classes.list}
                        key={each}
                      >
                        <ListItemIcon>
                          <CheckIcon className={classes.listItemIcon} />
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText}>
                          {each}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                  <Box className={classes.customButtonResponssive} mt={3}>
                    <Button
                      className={classes.customButton}
                      color="secondary"
                      component={Link}
                      size="large"
                      to="/contact"
                      variant="contained"
                    >
                      Get Started
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} sm={12}>
              <Box className={classes.buildExperiencesImg} textAlign="right">
                <StaticImage
                  alt="Best WhatsApp CRM"
                  className={classes.img}
                  height={"600px"}
                  placeholder="blurred"
                  src="../images/v2/whatsappcrm/build.png"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.faqSection} py={8}>
        <Container>
          <HeaderTypography
            component="h2"
            fontSize={40}
            textAlign="center"
            marginTop="-20px"
          >
            Frequently asked questions
          </HeaderTypography>
          {frequentlyAskedQuestions.map((each, index) => (
            <div key={each.title}>
              <Box
                className={classes.faqTitle}
                m={0}
                onClick={handleClickCollapse(index)}
              >
                <Box component="h3" fontSize={20} fontWeight={600} my={2}>
                  <HeaderTypography
                    className={classes.faqTitle}
                    component="h2"
                    fontFamily="'SourceSansPro', sans-serif"
                    fontSize={20}
                    fontWeight={600}
                  >
                    {each.title}
                  </HeaderTypography>
                </Box>
                <div>
                  <IconButton
                    aria-label="Expand or Collapse"
                    className={classes.expendButtonFaq}
                  >
                    {index !== open ? (
                      <AddIcon color="#74777a" fontSize="small" />
                    ) : (
                      <RemoveIcon color="#74777a" fontSize="small" />
                    )}
                  </IconButton>
                </div>
              </Box>
              <Collapse in={open === index} timeout="auto" unmountOnExit>
                <p className={classes.faqAnswer} fontSize={18}>
                  {each.ans}
                </p>
              </Collapse>
              {index + 1 !== frequentlyAskedQuestions.length && (
                <Divider className={classes.collapseDivider} light />
              )}
            </div>
          ))}
        </Container>
      </Box>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "3rem 1rem",
          borderBottom: "1px solid #70707038",
        }}
      >
        <Box maxWidth={1015} textAlign="center">
          <Box
            onClick={() =>
              window.open(
                "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
              )
            }
            sx={{ cursor: "pointer" }}
            component={"img"}
            src={IMDA_SME_Large}
            alt={"IMDA Pre-approved Solution Provider"}
            maxWidth={"100%"}
            margin={0}
            width={"100%"}
            mb={0}
          />
          <Box className={classes.SmeStyling}>
            <ParagraphTypography
              color="text.secondary"
              fontSize={16}
              sx={{ padding: 0, margin: 0 }}
              textAlign="center"
              className={classes.smeFooterText}
            >
              SMEs are eligible for up to 50% Productivity Solutions Grant (PSG)
              support for the adoption of Pepper Cloud CRM solution, a
              Pre-Approved Solution under the IMDA SMEs Go Digital programme.
            </ParagraphTypography>
          </Box>
        </Box>
      </Container>

      <Box className={classes.connectcustomBlock} py={8}>
        <Container>
          <Box alignItems={"center"} display={"flex"} justifyContent={"center"}>
            <HeaderTypography
              color="#000"
              component="h2"
              fontSize={40}
              fontWeight={600}
              maxWidth={"700px"}
              mt={0}
              textAlign="center"
            >
              {" "}
              Connect with your customers on other popular channels
            </HeaderTypography>
          </Box>
          <Box
            alignItems={"center"}
            display={"flex"}
            justifyContent={"center"}
            my={4}
          >
            <Box className={classes.socialGrid}>
              <Box className={classes.socialGridItem}>
                <Box
                  alt="Sales CRM feature"
                  component="img"
                  height={{ sm: 40, xs: 40 }[width] || 89}
                  mr={{ sm: 2, xs: 2 }[width] || 3}
                  src={socialMediaImage}
                />
              </Box>
            </Box>
          </Box>
          <Box
            alignItems={"center"}
            display={"flex"}
            justifyContent={"center"}
            mt={4}
          >
            <Button
              className={classes.customButton}
              color="secondary"
              component={Link}
              size="large"
              variant="contained"
              to="https://peppercloud.com/multichannel-integration-for-sales-marketing"
            >
              Explore Channels
            </Button>
          </Box>
        </Container>
      </Box>
      <Box py={8}>
        <Container>
          <Box alignItems={"center"} display={"flex"} justifyContent={"center"}>
            <Box maxWidth={1024}>
              <HeaderTypography
                component="h3"
                display="flex"
                fontSize={40}
                fontWeight={700}
                justifyContent="flex-start"
                lineHeight={1.4}
                // mb={"10%"}
                mt={0}
                pl={1}
                textAlign="center"
              >
                Discover how WhatsApp CRM has empowered businesses to reach new
                heights
              </HeaderTypography>
              <Grid container>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Box className={classes.testimonialLogo}>
                    <Box
                      alt="Sales cycle in WhatsApp-enabled CRM"
                      className={classes.testImg}
                      component="img"
                      height={"auto"}
                      placeholder="blurred"
                      src={testimonialImage}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} md={12} sm={12}>
                  <Box className={classes.testimonialTextContainer} pl={"10%"}>
                    <Box
                      className={classes.testimonialText}
                      color={"#000"}
                      fontSize={"18px"}
                      lineHeight={"30px"}
                    >
                      Pepper Cloud CRM has boosted our business. Their
                      integration with WhatsApp has streamlined our
                      communication with customers and facilitated seamless team
                      coordination. The ability to automate workflows has
                      significantly reduced our manual workload.
                      <Box
                        color={"#000"}
                        component="p"
                        fontSize={"18px"}
                        fontWeight={600}
                        lineHeight={"30px"}
                        marginTop={"24px"}
                      >
                        Jeremy <br /> Director, Urban Moods
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        className={classes.growYourBussiness}
        px={2}
        py={{ sm: 10, xs: 8 }[width] || 12}
      >
        <HeaderTypography
          color="common.white"
          component="h2"
          fontSize={35}
          mt={0}
          textAlign="center"
        >
          Boost your sales with WhatsApp CRM solution
        </HeaderTypography>
        <Box
          alignItems={"center"}
          className={classes.whatappCrmPoints}
          display={"flex"}
          justifyContent={"center"}
          mt={6}
        >
          <Box
            alignItems={"center"}
            className={classes.whatappCrmPoint}
            display={"flex"}
            mr={10 || { sm: 0 }}
          >
            <Box alignItems={"center"} display={"flex"}>
              <Box
                alt="Sales CRM feature"
                component="img"
                height={{ sm: 20, xs: 20 }[width] || 21}
                mr={1}
                src={footerTick}
              />
            </Box>
            <Box>
              {" "}
              <ParagraphTypography
                color="#fff"
                fontSize="22px"
                m={0}
                textAlign="left"
              >
                Up to 50% PSG grant
              </ParagraphTypography>
            </Box>
          </Box>
          <Box
            alignItems={"center"}
            className={classes.whatappCrmPoint}
            display={"flex"}
            mr={10 || { sm: 0 }}
          >
            <Box alignItems={"center"} display={"flex"}>
              <Box
                alt="Sales CRM feature"
                component="img"
                height={{ sm: 20, xs: 20 }[width] || 21}
                mr={1}
                src={footerTick}
              />
            </Box>
            <Box>
              {" "}
              <ParagraphTypography
                color="#fff"
                fontSize="22px"
                m={0}
                textAlign="left"
              >
                24/7 Local support
              </ParagraphTypography>
            </Box>
          </Box>
          <Box
            alignItems={"center"}
            className={classes.whatappCrmPoint}
            display={"flex"}
            mr={10 || { sm: 0 }}
          >
            <Box alignItems={"center"} display={"flex"}>
              <Box
                alt="Sales CRM feature"
                component="img"
                height={{ sm: 20, xs: 20 }[width] || 21}
                mr={1}
                src={footerTick}
              />
            </Box>
            <Box>
              {" "}
              <ParagraphTypography
                color="#fff"
                fontSize="22px"
                m={0}
                textAlign="left"
              >
                No hidden charges
              </ParagraphTypography>
            </Box>
          </Box>
          <Box
            alignItems={"center"}
            className={classes.whatappCrmPoint}
            display={"flex"}
            mr={10 || { sm: 0 }}
          >
            <Box alignItems={"center"} display={"flex"}>
              <Box
                alt="Sales CRM feature"
                component="img"
                height={{ sm: 20, xs: 20 }[width] || 21}
                mr={1}
                src={footerTick}
              />
            </Box>
            <Box>
              {" "}
              <ParagraphTypography
                color="#fff"
                fontSize="22px"
                m={0}
                textAlign="left"
              >
                Built for SMEs
              </ParagraphTypography>
            </Box>
          </Box>
        </Box>
        <Box mt={6} textAlign="center">
          <WhiteButton
            color="primary"
            component={Link}
            size="large"
            to="/contact"
            variant="contained"
          >
            {`Get Started`}
          </WhiteButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export const query = graphql`
  query {
    oneAccountMultipleAgents: file(
      sourceInstanceName: { eq: "whatsapp-crm" }
      name: { eq: "One-account-multiple-agents" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    AutoReplyWithMessageTemplates: file(
      sourceInstanceName: { eq: "whatsapp-crm" }
      name: { eq: "Auto-reply-with-message-templates" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    addCustomNotesToConversations: file(
      sourceInstanceName: { eq: "whatsapp-crm" }
      name: { eq: "Add-custom-notes-to-conversations" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    autoLeadCapture: file(
      sourceInstanceName: { eq: "whatsapp-crm" }
      name: { eq: "auto-lead-capture" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    mergeContact: file(
      sourceInstanceName: { eq: "whatsapp-crm" }
      name: { eq: "merge-contact" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
    bordcastMessage: file(
      sourceInstanceName: { eq: "whatsapp-crm" }
      name: { eq: "bordcast-message" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 650, placeholder: BLURRED)
      }
    }
  }
`;
export default WhatsAppCrm;
